import React from 'react';
import { View, Text, ButtonLink, Link } from '../../components/common';
import Layout from '../../components/layout';
import config from '../../config';
import { colors } from '../../style';

function ExpiredPage() {
  return (
    <Layout>
      <View className="items-center mt-16 px-4">
        <Text className="text-xl font-bold mb-4">Manage Account</Text>
        <Text>
          Your temporary account link has expired. You can create a new one within Actual or entering
          your email <Link to="/account/login">here</Link>.
        </Text>
      </View>
    </Layout>
  );
}

export default ExpiredPage;
